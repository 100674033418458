.contact__container.container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 40%;
  gap: 10%;
  align-items: center;
  background: white;
  margin-top: 4rem;
 
}
.input__text {
  font-size: 0.9rem;
   font-family: 'Montserrat';
   font-weight: 400;
  font-style: normal;
}
.contact__heading {
  width: 80%;
  height: fit-content;
  top: 0%;

  font-family: "Superclarendon";
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  line-height: 10px;
  text-align: start;
  text-align: start;
  margin-left: 20%;
  color: #101820;
  margin-bottom: 0% !important;
}

.contact__line {
color: #871c1e;
}

.address {
  text-decoration: none;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  align-items: center;
}

.contact__descriptions {
  width: 80%;
  padding: 0rem;
  text-align: start;
  margin-left: 20%;

}

.contact__description {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact__option-icons {
  font-size: 1.5rem;
  padding-top: 2rem;  
  margin-bottom: 0.2rem;
}

.contact__copyrights {
  gap: 0;
}

.contact__icon {
  margin-right: 1.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* ============ FORM =========== */
form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

.contact__name {
  display: grid;
  grid-template-columns: repeat(2, 47%);
  padding-top: 0.5rem;
  column-gap: 6%;
  padding-bottom: 1rem;
}

input,
textarea {
  width: 100%;
  padding: 1rem 0rem 1rem 0.5rem;
  background: white;
  border: 1px solid #000000;
  resize: none;
  color: var(--color-white);
}

.contact__option-error {
  color: red;
  align-self: flex-start;
}

.contact__btn {
  box-sizing: border-box;

  /* Auto layout */
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100px;
  height: 40px;
  margin-top: 1rem;
  border: 1px solid #101820;
  background: transparent;
}

/* =========== MEDIA VIEW (MEDIUM DEVICES) =========== */
@media screen and (max-width: 1024px) {
  .contact__heading {
    font-size: 50px;
    line-height: 20px;
    /* text-align: center;
    align-items: center; */
    left: 0%;
  }

  .contact__container.container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    text-align: center;
  }
  form {
    width: 90%;
    left: 0;
    /* padding: 5%; */
    justify-self: center;
    /* align-self: center; */
  }
  .contact__name {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    width: 100%;
  }
  .contact__options {
    align-self: center;
    justify-self: center;
    text-align: center;
  }
}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
  .contact__container.container {
    width: var(--container-width-sm);
  }
  .contact__btn {
    background: #871c1e;
    color: white;
    border: none;
    /* align-self: center; */
    justify-self: center;
  }
  .contact__heading {
    font-size: 40px;
    
  }
  .contact__options{
    width: 100%;
    margin-right: 25%;

  }
}
