.logInContainer{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
}

.videoContainer {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background: url(../../../assets/services-bg.webp); */
    /* background-size: cover !important; */
    /* background-repeat: no-repeat; */
}

.contantWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.contantContainer {
    width: 60%;
    /* margin-left: 20%; */
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.logoContainer {
    width: 100%;
    align-items: center;
    text-align: center;
 }

 .title{
    font-size: calc(1rem + 0.6vw);
    font-family: 'Montserrat';
    font-weight: bold;
    padding-bottom: 2%;
 }

 .description {
    font-size: calc(0.7rem + 0.3vw);
    font-family: 'Montserrat';
    padding-bottom: 10%;
 }

 .button {
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: #8E1B21;
    color: #ffffff;
    border-radius: 10px;
    font-size: calc(0.7rem + 0.3vw);
    font-family: 'Montserrat';
    cursor: pointer;
 }