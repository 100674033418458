.itemsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 4.4%;
    margin-bottom: 3%;

}
.itemsHeading{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

}
.tableHeading {
    background-color: #6b0231;
    /* background-color: #530385; */
    color: white;
  }
  .headContainer {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
}
.itemHead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}
.itemsTableContainer {
    width: 95%;
}
.headingRow {
    background-color: #5f002b;
    color: white !important;
    text-align: center !important;
    font-size: large !important;
}
.subHead{
    padding-top: 1%;
    padding-left: 1.5%;
    font-weight: 700;
}
.iconsCon{
    position: relative;
    display: flex !important;
    flex-direction: row !important;
}
.itemRow {
    background-color: #f8ffff;
    
}
.itemRow:hover {
    background-color: #7385a8;
}

.addbtn{
    position: relative;
    font-size: 30px;
}