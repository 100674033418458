.itemsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.4%;
    margin-bottom: 3%;

}
.itemsHeading{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

}
.headContainer {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
}
.tableHeading {
    background-color: #ce005d;
    /* background-color: #530385; */
    color: white;
  }
.itemHead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}
.subHead{
    padding-top: 1%;
    padding-left: 1.5%;
    font-weight: 700;
}

.form_con{
    width: 88%;
    margin: 2em auto;
}

.inputsContainer{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 31%);
    justify-content: space-between;
    font-family: var(--font-family);
  }
  
.inputt{
    color: var(--primary-color) !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    border: none !important;
    height: 3.5em !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    }

    
.btnsContainer{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 2em auto 2em auto ;
    justify-content: space-between; 
  }

    
.saveBtn{
    width: 48%;
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #1a2c50;
    color: white;
    border: none;
    border-radius: 0;
  }

  
.cancel{
    width: 48%;
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #7c0038;
    color: white;
    border: none;
    border-radius: 0;
}