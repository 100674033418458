.home {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
}
.cssssss {
  background-color: #1a2c50;
  /* background-color: #530385; */
  color: white;
}

.sidebar{
  /* background-color: #763cbd  !important; */
  background-color: #1a2c50 !important;
  /* background-color: #38021a  !important; */
  color: white;
  height: 100%;
}
.sidebartop{
  /* background-color: #11C0C5 !important; overlay sidecolor*/
  /* background-color: #135565 !important; overlay color*/
  background-color: white  !important;
}

.sidebartop1{
  /* background-color: #763cbd !important; */
  /* background-color: #9c5beb  !important; */
  background-color: #1a2c50  !important;
  color: white;
}
.sidebar a{
  color: white;
}

.icons {
  color: white;
}

.arrow{
  color:black;
}

.overlay {
  background-image: linear-gradient(to right,#38021A  , #ce005d);
  opacity: 0.7;
}

.overlay1 {
  position: relative;
  width: 10px !important;
  left: 0%;
  background-color:#38021A  ;
}
.logooo{
  margin-left: 7% !important;
}
.test{
  position: absolute;
    width: 50vmin;

      top: 50%;
      left: 40%; 
      transform: translate(-50%, -50%);
}