.team__container.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headding-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  font-family: "Superclarendon";
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.4rem + 1vw);
  line-height: 20px;
  color: #101820;
  margin-bottom: 2%;
  margin-top: 2%
}
.team__heading{
  position: relative;
  width: 100%;
  text-align: end !important;
  align-items: end !important;
  

}
.ai__heading{
  position: relative;
  width: 100%;
}
.headding1 {
  position: relative;
  width: fit-content;
  text-align: end;
  cursor: pointer;
  padding-bottom: 3%;
  margin-left: auto;
  margin-right: 3%;
}

.headding2 {
  position: relative;
  width: fit-content;
  text-align: end;
  cursor: pointer;
  padding-bottom: 3%;
  margin-left: 3%;

}

.headding1_active{
  border-bottom: 2px solid #871c1e;
}
.headding2_active{
  border-bottom: 2px solid #871c1e;
}

.cards__container {
  position: relative;
  display: inline;
  width: 80%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
  display: grid;
  grid-template-columns: repeat(4 , 250px);
  column-gap: 2rem;
  row-gap: 2rem;
}

.card__container{
  height: 100%;
  position: relative;
  overflow: hidden;
}

.contant-container{
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)) !important;
}

.cards-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.name {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.7rem + 0.3vw);
  line-height: 113.5%;
  /* or 23px */
  
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: start;
  color: #ffffff;
  z-index: 122;
  padding-left: 6%;
  padding-bottom: 3%;
  padding-top: 20%;
  margin: 0px;
}

.description {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.8rem + 0.3vw);
  line-height: 113.5%;
  /* or 23px */
  
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: start;
  color: #ffffff;
  z-index: 122;
  padding-left: 6%;
  padding-bottom: 6%;
  margin: 0px;
  
}

.ai__container {
  /* background-color: black; */
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: white !important ;
}
.ai__female__container{
  position: relative;
  width: calc( 3rem + 18vw);
  height: calc( 5rem + 21vw);
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  cursor: pointer;
}
.ai-image1 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.ai__male__container{
  position: relative;
  width: calc( 3rem + 18vw);
  height: calc( 5rem + 21vw);
    display: flex;
  flex-direction: column;
  margin-left: 10%;
  cursor: pointer;

}
.ai-image2 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

}
.ai-text1 {
  position: absolute;
  width: 96%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  text-align: start;
  font-size: calc(0.3rem + 0.85vw);
  z-index: 2;
  color: white;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
  padding-left: 4%;
  height: 20%;
  padding-bottom: 4%;
  top: 73%;
}
.ai-text2 {
  position: absolute;
  width: 96%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.3rem + 0.85vw);
  text-align: start;
  z-index: 2;
  color: white;    
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
  padding-left: 4%;
  height: 20%;
  padding-bottom: 4%;
  top: 73%;
}



 /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
 @media screen and (max-width: 1200px) {
  .cards__container{
    grid-template-columns: repeat(3, 300px);
  }
 }
 @media screen and (max-width: 950px) {
  .cards__container{
    grid-template-columns: repeat(2, 300px);
  }
 }
  @media screen and (max-width: 600px) {
    .cards__container{
      grid-template-columns: 100%;
      width: 60%;
    }
    .ai__female__container{
      margin-left: 30%;
    }
    .ai__male__container{
      margin-left: 20%;
    }
 }
 .popup-image {
  position: relative;
  width: 70%;
  height: 100%;
  border-radius: 10%;
}