.dropdown {
  height: fit-content;
  cursor: pointer;
  text-align: start;
  text-decoration: none;
  z-index: 1;
  transform: perspective(1000px);
  line-height: 33px;
}

.dropdown-menu {
  list-style-type: none;
  margin: 0; 
  margin-top: 0%;
  padding: 0;
  position: relative;
  width: 95%;
  left: 0;
  opacity: 1;
  text-align: center;
  top: 100%;
  visibility: visible;
  z-index: -99999;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: start;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  width: 100%;
  transition:all 0.3s ease-in-out;
  transform: scale(1, 1);
} */
.image11 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.services {
position: relative;
width: 100%;
height: 100%;
padding-bottom: 4%;
background: url(../../../assets/services-bg.webp);
background-size: cover !important;
background-repeat: no-repeat;
opacity: 0.9;
backdrop-filter: blur(2px);
}

.service-heading {
position:relative;
align-self: center;
padding-top: 3rem;

font-family: 'Superclarendon';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 50px;

color: #FFFFFF;
}

.services-container {
position:relative;
width: 85%;
height: 100%;
text-align:start;
margin-left: 7.5%;
margin-top: 0rem;
display:grid;
grid-template-rows: repeat(2, auto-fill);
row-gap: 10rem;

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 42px;

align-items: center;
letter-spacing: -0.02em;
text-transform: uppercase;

color: #F0F0F0;
}

.services-rows {
position:relative;
width:100%;
height:fit-content;
margin-top:4rem;
margin-left: 2rem;
display:grid;
grid-template-columns: 15% 15% 25% 25%;
row-gap: 4rem;
column-gap: 5rem ;
/* gap: 2rem; */
cursor: pointer;
}

.show-more {
  position: relative;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  padding-bottom: 0%;
  padding-top: 4%;


  font-family: 'Montserrat';
font-style: italic;
font-weight: 300;
font-size: 14px;
line-height: 17px;

color: white;
}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 1100px) {
  .services-rows {
    margin-left: 0rem;
    column-gap: 4rem;
  }
}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
  .services {
    background: url(../../../assets/ourservice-mobile-bg.png);
  }
  .services-rows {
    grid-template-columns: 48% 48%;
    margin-top: 0rem;
    margin-left: 0rem;
  }
  .dropdown {
    font-size: 22px;
  }
  .dropdown-menu {
    font-size: 16px;
  }
  .show-more {
    padding-top: 20%;
  }
}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
  .services-rows {
    grid-template-columns: 1fr;
    margin-top: 2rem;
    margin-left: 0rem;
    row-gap: 9rem;
  }

  .dropdown {
    width: 48%;
    font-size: 15px;
    left: 0%;
    text-align: start;
    padding-right: 0.5rem;
  }
  .dropdown-menu {
    position: absolute;
    width: 100%;
    top: 0%;
    left: 100%;
    padding-left: 0%;
    font-size: 11px;
  }
  .service-heading {
    font-size: 28px;
  }
  
}