footer {
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 45% 12% 14% 22%;
  justify-content: center;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.5rem + 0.5vw);
  line-height: 10px;
  /* identical to box height */
  background: url(../../../assets/services-bg.webp);
  background-size: cover !important;
  background-repeat: no-repeat;
  letter-spacing: -0.02em;

  color: #ffffff;
}

.footer__logo {
  position: relative;
  font-weight: 500;
  align-self: center;
  text-align: start;
  left: 4.5rem;
  display: inline-block;
  cursor: pointer;
}

.permalinks {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0rem;
  justify-content: center;
  text-align: start;
   padding-top: 0.9rem; 
  padding-bottom:0rem;
  line-height: 0px;


}
.permalinks__options{
  cursor: pointer;
  line-height: 0px;
}
.permalinks__options:hover{
  
  color: #D3A562;
}

.permalinks__head:hover{
  
  color: #D3A562;
}

.permalinks__head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-top: 1rem; 
  line-height: 0px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;

}
@media screen and (max-width: 600px) {
  .logoo{
    width: 200px;
  }
}

.footer__socials {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-bottom: 0rem;
  text-align: start;
  line-height: 0px;
  /* padding-top: 3rem; */
}

.footer__copyright {
  position: relative;
  /* padding-top: 3.5rem; */
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 1fr;
  text-align: start;
}

.footer_address {
  line-height: 30px;
}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
  footer {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
  .footer__logo{
    left: 0%;
    justify-self: center;
  }
  .permalinks {
    display: none ;
  }

  .footer__socials {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .footer__copyright {
    text-align: center;
    margin-bottom: 2rem;
  }

  .links {

    font-size:100%;
   
    cursor: pointer;
  }

    .Links:hover{
        color: red;
    }
    

}
