
.form{
        width: 100% !important;
        margin: 0 auto !important;
        align-items: center !important;
        text-align: center !important;
        justify-items: center !important;
        margin-bottom: 1em !important;
        display: flex;
        flex-direction: column !important;
  }

  .form 
  .ant-input {
    border-radius: 0 !important;
    box-shadow: none ;
    height: 3em !important;
    background: #fff !important;
    max-width: 100%;
    margin: 0;
  }

  .form 
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
}

  .form 
  .ant-input::placeholder {
    color: rgba(17, 17, 17, 0.5) !important;
  }

  
  .form 
  .ant-input-password::placeholder {
    color: rgba(17, 17, 17, 0.5) !important;
  }
  
  .form
  .ant-input-password{
    background-color: #fff !important;
    height: fit-content !important;
    font-size: medium !important;
  }

  .ant-checkbox{
    margin: 0;
    padding: 0;
    justify-self: flex-start;
    align-self: flex-start;
  }

  .ant-checkbox p{
    margin: auto 0 !important;
    font-size: calc(0.8em + 0.3vw) !important;
    color: var(--primary-color) !important;
    font-weight: 400 !important;
  }
  .form .ant-form-item-label > label {
    font-size: calc(0.7em + 0.2vw); /* Adjust as needed */
    color: var(--primary-color); /* Adjust as needed */
    margin-bottom: 0.5em; /* Adjust as needed */
  }

  @media screen and (max-width:951) {
    .form 
    .ant-form-item-control-input-content {
      max-width: 100% !important;
  }
    
  }