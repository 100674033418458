.maincontainer{
    width:70%;
    height:80vh;
    overflow: hidden;
    overflow-y: scroll;
    top: 50%;
    transform: translate(22%, 13%);
    row-gap: 20px;

    background-color: white;
}

.mainheader{
    width: 100%;
    height: 70px;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 4%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.mainhead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}

.storecontainer {
    position: relative;
    width: 95%;
    left: 1%;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 30px;
    padding-top: 15px;
    padding-bottom: 0px;
}
.inputs_location_container {
    display: grid;
    grid-template-columns: 65% 35% ;
    gap: 2rem;
    padding: 2%;
    }
.card1{
    width: 100%;
    padding-bottom: 3%;
    margin-bottom: 2%;
    margin-left: 2%;
}
.card2{
    position: relative;
    margin-bottom: 30px;
    width: 93%;
    left: 3.5%;
}

.heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    background-color: #38021A;
    color: white;
    /* border-radius: 10px; */
    border-bottom: 0.1px solid #ccc ;
}

.inputs_container{
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 42%);
    left: 30px;
    column-gap: 30px;
    row-gap: 15px;
    padding-bottom: 44px;
}

.subs_dates{
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
    left: 30px;
    column-gap: 30px;
    padding-bottom: 20px;


    background: transparent;
}

.inputs_container1{
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 42%);
    left: 30px;
    column-gap: 30px;
    padding-bottom: 30px;
}

.inputs_container_location{
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 20%);
    left: 30px;
    column-gap: 30px;
    row-gap: 15px;
    padding-bottom: 30px;
}

.checkbox {
    position: relative;
    display: flex;
    align-items: end;
}

.btns{
    position: relative;
    width: 92%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 30px;
    left: 3.7%;
    margin-bottom: 30px;
}

.btns_mini {
    display: none;
}

.btncancel {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #7c0038;
    color: white;
    border: none;
    /* border-radius: 10px; */
}

.btnsave {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #1a2c50;
    color: white;
    border: none;
    /* border-radius: 10px; */
}
.input_description{
    grid-column: 1fr;
}

.imagee_container{
    text-align: center !important;
    align-items: center !important;

}

.editicon {
    position: absolute;
    top: 21%;
      left: 78.5%; 
      transform: translate(-50%, -50%);
    background-color: white;
}

.info_rows{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

 /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
 @media screen and (max-width: 1240px) {
    .storecontainer {
        grid-template-columns: 1fr;
    }

    .btns {
        display: none;
    }

    .btns_mini {
        position: relative;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    left: 3.5%;
    margin-bottom: 30px;
    }

    .btnsave {
        margin-bottom: 20px;
    }
}